import React from 'react';

import CustomLink from '@components/global/CustomLink';

import useTranslation from '@hooks/useTranslation';
import PropTypes from 'prop-types';

import styles from './ResourceCard.module.css';

export const ResourceCard = ({
    link,
    title,
    description,
    postType,
    postCategories,
    companyOrBusinessRole,
    picture,
    collectionsAmount,
    ...props
}) => {
    const { t } = useTranslation('pages/resources/_blocks/results');
    let categoryList;
    let companyList;

    if (postCategories) {
        categoryList = postCategories.map((category, index, arr) => {
            return (
                <CustomLink
                    key={category.name}
                    prefetch={false}
                    href={{
                        pathname: '/resources',
                        query: { category: category.name },
                    }}
                    data-ats-resources-search-results="topic_link"
                    data-track="search_results_topic_link"
                    rel="nofollow"
                >
                    {category.name}
                    {index === arr.length - 1 ? '' : ', '}
                </CustomLink>
            );
        });
    }

    if (companyOrBusinessRole) {
        companyList = companyOrBusinessRole.map((company, index, arr) => {
            return (
                <CustomLink
                    key={company.name}
                    prefetch={false}
                    href={{
                        pathname: '/resources',
                        query: { 'company-or-business-role': company.name },
                    }}
                    data-ats-resources-search-results="company_or_business_role_link"
                    data-track="search_results_company_or_business_role_link"
                    rel="nofollow"
                >
                    {company.name}
                    {index === arr.length - 1 ? '' : ', '}
                </CustomLink>
            );
        });
    }

    return (
        <div
            className={[styles.article, styles.resource, styles[postType.key]].join(' ')}
            {...props}
            data-test="search-listing-item"
            data-ats-resources-search-results="item"
            data-track="search_listing_item"
        >
            <figure>
                <a
                    target="_blank"
                    href={link}
                    aria-label={title}
                    data-ats-resources-search-results="cover"
                    data-track="search_listing_cover"
                >
                    {picture ||
                        (postType.key == 'collections' && (
                            <div className={styles.inner}>
                                <div className={styles.count}>{collectionsAmount || 0}</div>
                                <p>{t('resourcesResultsCollectionResources')}</p>
                            </div>
                        )) || <div className={styles.placeholder}></div>}
                </a>
            </figure>
            <div
                className={styles.content}
                data-ats-resources-search-results="content"
                data-track="search_listing_content"
            >
                <h2>
                    <a
                        href={link}
                        data-ats-resources-search-results="link"
                        data-track="search_results_link"
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                </h2>
                {description && (
                    <p
                        data-ats-resources-search-results="description"
                        data-track="search_results_description"
                        dangerouslySetInnerHTML={{ __html: description }}
                    ></p>
                )}
                <div className={styles.category}>
                    <ul>
                        <li className={styles.type}>
                            <CustomLink
                                prefetch={false}
                                href={{
                                    pathname: '/resources',
                                    query: { type: postType.key },
                                }}
                                data-ats-resources-search-results="type_link"
                                data-track="search_results_type_link"
                                rel="nofollow"
                            >
                                {postType.name}
                            </CustomLink>
                        </li>
                        {categoryList && <li className={styles.topic}>{categoryList}</li>}
                        {companyList && (
                            <li
                                className={styles.company_or_business_role}
                                data-page="results"
                            >
                                {companyList}
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ResourceCard;

ResourceCard.propTypes = {
    link: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    picture: PropTypes.object,
    collectionsAmount: PropTypes.number,
    postType: PropTypes.object,
    postCategories: PropTypes.array,
    companyOrBusinessRole: PropTypes.array,
};

ResourceCard.defaultProps = {
    link: null,
    title: null,
    description: null,
    picture: null,
    collectionsAmount: null,
    postType: null,
    postCategories: null,
    companyOrBusinessRole: null,
};

//---------------------- USE INSTRUCTION ------------------------:

// import ResourceCard from '@components/pages/resources/ResourceCard.js';

// const Picture = ({ title, imgSrc, imgSrc2x }) => {
//     return (
//         <picture>
//             <source srcSet={`${imgSrc} 123w, ${imgSrc2x} 246w`} sizes="123px" />
//             <img src={imgSrc2x} alt={title} />
//         </picture>
//     );
// };

// <ResourceCard
//     link="https://www-us.local.gr-dev.me/resources/guides/sms-marketing-automation-ideas"
//     title="7 Connectable SMS Marketing Automation Ideas"
//     description="Want to make more impact with your SMS campaigns? Take a look at these 7 SMS marketing automation campaign ideas and learn how to set them up all by yourself."
//     picture={
//         <Picture
//             title="7 Connectable SMS Marketing Automation Ideas"
//             imgSrc="https://us-wd.gr-cdn.com/resources/sites/2/2021/12/1546/Connectable-SMS-Marketing-Automation-Ideas-cover-260x384.jpg"
//             imgSrc2x="https://us-rd.gr-cdn.com/246x/https://us-wd.gr-cdn.com/resources/sites/2/2021/12/1546/Connectable-SMS-Marketing-Automation-Ideas-cover-260x384.jpg"
//         />
//     }
//     postType={{
//         key: 'guides',
//         name: 'Guides',
//         url: 'https://www.getresponse.com/resources?type=guides',
//     }}
//     postCategories={[
//         {
//             name: 'Email marketing',
//             url: 'https://www.getresponse.com/resources?category=Email+marketing',
//         },
//         {
//             name: 'Marketing automation',
//             url: 'https://www.getresponse.com/resources?category=Marketing+automation',
//         },
//         {
//             name: 'Lead generation',
//             url: 'https://www.getresponse.com/resources?category=Lead+generation',
//         },
//     ]}
//     companyOrBusinessRole={[
//         {
//             name: 'Marketing manager',
//             url: 'https://www.getresponse.com/resources?company-or-business-role=Marketing+manager',
//         },
//         {
//             name: 'Mid and large companies',
//             url: 'https://www.getresponse.com/resources?company-or-business-role=Mid+and+large+companies',
//         },
//     ]}
// />

// <ResourceCard
//     link="https://www-us.local.gr-dev.me/resources/videos/assign-custom-subdomain-to-landing-pages"
//     title="How to Assign a Custom Subdomain to Your Landing Pages"
//     description="Want to use your own subdomain with your landing page? In just over two minutes, we’ll show how you can assign a custom subdomain to the landing pages you build with GetResponse Landing Page Creator. It only takes a couple of minutes to ensure that your pages match your company’s branding."
//     postType={{
//         key: 'videos',
//         name: 'Video',
//         url: 'https://www.getresponse.com/resources?type=videos',
//     }}
//     postCategories={[
//         {
//             name: 'GetResponse Tutorials',
//             url: 'https://www.getresponse.com/resources?category=GetResponse+Tutorials',
//         },
//         {
//             name: 'Content creation',
//             url: 'https://www.getresponse.com/resources?category=Content+creation',
//         },
//         {
//             name: 'Ecommerce',
//             url: 'https://www.getresponse.com/resources?category=Ecommerce',
//         }
//     ]}
//     companyOrBusinessRole={[
//         {
//             name: 'Entrepreneur',
//             url: 'https://www.getresponse.com/resources?company-or-business-role=Entrepreneur',
//         }
//     ]}
// />

// <ResourceCard
//     link="https://www-us.local.gr-dev.me/resources/videos/assign-custom-subdomain-to-landing-pages"
//     title="How to Assign a Custom Subdomain to Your Landing Pages"
//     description="Want to use your own subdomain with your landing page? In just over two minutes, we’ll show how you can assign a custom subdomain to the landing pages you build with GetResponse Landing Page Creator. It only takes a couple of minutes to ensure that your pages match your company’s branding."
//     postType={{
//         key: 'collections',
//         name: 'Collections',
//         url: 'https://www.getresponse.com/resources?type=collections',
//     }}
//     postCategories={[
//         {
//             name: 'Landing pages',
//             url: 'https://www.getresponse.com/resources?category=Landing+pages',
//         }
//     ]}
//     companyOrBusinessRole={[
//         {
//             name: 'Entrepreneur',
//             url: 'https://www.getresponse.com/resources?company-or-business-role=Entrepreneur',
//         },
//         {
//             name: 'Online marketer',
//             url: 'https://www.getresponse.com/resources?company-or-business-role=Online+marketer',
//         }
//     ]}
// />
